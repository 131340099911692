import { h } from 'preact';

function Icon({...restProps}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="84"
      viewBox="0 0 218 84"
      {...restProps}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#3A0B62"
          d="M77.974 31.843l9.934 9.996v41.986l-9.934-9.996z"
        ></path>
        <path fill="#255AD8" d="M0 0h143.292v41.913H0z"></path>
        <path
          fill="#1D4BB8"
          d="M143.292 0l9.934 9.997v41.986l-9.934-9.997z"
        ></path>
        <path fill="#53128B" d="M87.908 41.913h129.82v41.912H87.908z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M24.68 31.331v-8.23h8.334v8.23h4.676V10.413h-4.676v8.738H24.68v-8.738h-4.676V31.33h4.676zm33.604 0v-4.13H47.523v-4.339h9.383v-3.98h-9.383v-4.31h10.432v-4.16H42.846v20.92h15.438zm11.332 0c3.997 0 6.97-.948 8.918-2.843 1.948-1.895 2.923-4.469 2.923-7.72 0-3.253-1-5.792-2.998-7.617-1.998-1.826-4.866-2.738-8.603-2.738H62.45V31.33h7.165zm.48-4.13h-2.968V14.483h2.608c2.378 0 4.142.553 5.29 1.66 1.15 1.108 1.724 2.689 1.724 4.744 0 2.055-.574 3.621-1.723 4.698-1.15 1.078-2.793 1.616-4.932 1.616zm25.09 4.37c3.677 0 6.555-1.158 8.634-3.472v-7.422h-4.707v5.746c-1.079.619-2.428.928-4.047.928-1.618 0-3.012-.594-4.182-1.78-1.169-1.188-1.753-2.769-1.753-4.744 0-1.975.61-3.566 1.828-4.773 1.22-1.207 2.718-1.81 4.497-1.81.92 0 1.774.154 2.563.463.79.31 1.654.873 2.593 1.69l2.428-3.5c-2.218-1.976-4.831-2.963-7.839-2.963s-5.576 1.037-7.704 3.112-3.192 4.664-3.192 7.766c0 3.102 1.044 5.671 3.132 7.706 2.089 2.035 4.672 3.053 7.75 3.053zm28.239-.24v-4.13h-10.762v-4.339h9.383v-3.98h-9.383v-4.31h10.432v-4.16h-15.109v20.92h15.439zM112.585 73.334v-8.21h9.229v-4.105h-9.229v-4.524h9.708V52.39h-14.382v20.944zm22.352.21c2.617 0 4.764-.814 6.442-2.442 1.678-1.628 2.517-3.96 2.517-6.997V52.39h-4.674v11.566c0 1.677-.385 3-1.154 3.97-.769.968-1.812 1.453-3.13 1.453-1.32 0-2.368-.48-3.147-1.438-.779-.98-1.168-2.308-1.168-3.985V52.39h-4.675v11.715c0 3.017.844 5.344 2.532 6.982 1.688 1.638 3.84 2.457 6.457 2.457zm18.487-.21V60.21l9.978 13.124h4.674V52.39h-4.674v13.483L153.125 52.39h-4.375v20.944z"
        ></path>
        <path
          fill="#FEB502"
          fillRule="nonzero"
          d="M175.186 68.3c.74 0 1.363.256 1.873.765.51.51.764 1.134.764 1.873 0 .739-.255 1.363-.764 1.872-.51.51-1.134.764-1.873.764-.739 0-1.368-.25-1.887-.749-.5-.52-.75-1.148-.75-1.887 0-.74.255-1.364.764-1.873.51-.51 1.134-.764 1.873-.764zm14.622-15.91l.427.005c3.527.067 6.251.98 8.172 2.737 1.998 1.828 2.996 4.37 2.996 7.626 0 3.256-.973 5.833-2.921 7.73-1.948 1.898-4.919 2.847-8.914 2.847h-7.16V52.39h7.4zm-14.622 8.03c.74 0 1.363.255 1.873.765s.764 1.133.764 1.872c0 .74-.255 1.364-.764 1.873-.51.51-1.134.764-1.873.764-.739 0-1.368-.25-1.887-.749-.5-.52-.75-1.148-.75-1.888 0-.739.255-1.363.764-1.872.51-.51 1.134-.764 1.873-.764z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
