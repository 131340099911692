import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        fill="#0052E9"
        d="M29.692 0H0v32h32V0h-2.308zM11.351 24.188H7.454V12.463h3.897v11.723zM9.402 10.863h-.025c-1.308 0-2.153-.9-2.153-2.025 0-1.15.871-2.025 2.204-2.025 1.333 0 2.154.875 2.179 2.025 0 1.125-.846 2.025-2.205 2.025zm16 13.325h-3.897v-6.272c0-1.576-.564-2.651-1.974-2.651-1.076 0-1.718.725-2 1.425-.102.25-.127.6-.127.95v6.547h-3.897s.051-10.623 0-11.723h3.897v1.66c.517-.799 1.444-1.935 3.511-1.935 2.564 0 4.486 1.676 4.486 5.276v6.723z"
      ></path>
    </svg>
  );
}

export default Icon;
