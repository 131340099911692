import { h } from 'preact'
import { Link } from 'preact-router/match';

import Logo from '../../assets/images/Logo'
import Linkedin from '../../assets/images/Linkedin'

import style from './style.sass'

const Footer = () => (
	<footer class={style.footer}>
		<div class={style.container}>
			<Link class={style.logo} href="/">
				<Logo class={style.logo_icon} />
			</Link>
			<div class={style.textBox}>
				<p class={style.text}>We are a Hedge Fund That Empowers You to Simplify Your Whole World of Investment </p>
				<p class={style.copyright}>Copyright © 2020</p>
			</div>
			<a class={style.social} target="_blank" href="https://www.linkedin.com/company/hedgefun-d/">
				<Linkedin />
			</a>
		</div>
	</footer>
);

export default Footer;
