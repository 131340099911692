import { h } from 'preact'
import { Link } from 'preact-router/match';

import Logo from '../../assets/images/Logo'
import style from './style.sass'

const Header = () => (
	<header class={style.header}>
		<div class={style.container}>
			<Link class={style.logo} href="/">
				<Logo class={style.logo_icon} />
			</Link>
			<nav class={style.nav}>
				<Link class={style.nav_item} activeClassName={style.active} href="/">Home</Link>
				<Link class={style.nav_item} activeClassName={style.active} href="/contact">Contact us</Link>
			</nav>
		</div>
		<div class={style.svg_bg}></div>
	</header>
);

export default Header;
